<template>
  <div>
    <ValidationObserver v-slot="context">
      <form @submit.prevent="submit(context)">
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>Email *</span>
            </FormLabel>
            <FormContent class="flex flex-col">
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Email"
                rules="required">
                <vs-input 
                  v-model="formData.email"
                  placeholder="Email: ตัวอย่าง example@lh.co.th"
                  class="w-full" 
                  name="email" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel/>
            <FormContent>
              <vs-button 
                class="w-1/3 mr-4" 
                @click="submit(context)">Submit</vs-button>
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import useBlackListEmail from '@/use/useBlackListEmailUtil'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'
import get from 'lodash/get'
import omit from 'lodash/omit'

export default {
  name: 'RedirectionForm',
  components: {
    ValidationObserver,
  },
  setup(props, ctx) {
    const { success: notifySuccess, error: notifyError } = useNotify(ctx)
    const {
      root: { $store, $router, $vs },
    } = ctx
    const { initialData, formData, fetchByRouteId } = useBlackListEmail(ctx)
    const crudForm = useCrud(ctx, 'blacklistEmail')
    crudForm.routePrefix.value = 'blacklistEmail'

    onMounted(() => {
      fetchByRouteId()
    })

    const updateItem = ({ validate }) => {
      validate().then((result) => {
        if (!result) {
          notifyError({
            text: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง'
          })
          return
        }
        $vs.loading()

        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let created_by = formData.created_by
        const updated_by = email

        // add user created by
        if (!created_by) {
          created_by = email
        }

        $store
          .dispatch('blacklistEmail/updateItem', { initialData,
            formData: {
              ...omit(formData, ['updated_at']),
              created_by,
              updated_by
            }
          })
          .then(() => {
            $router.push({ name: 'blacklistEmail' })
            $vs.loading.close()
            notifySuccess({ text: `${formData.id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            notifyError({ text: JSON.stringify(error) })
          })
      })
    }

    const submit = (ctx) => {
      if (formData.id) {
        updateItem(ctx)
      } else {
        crudForm.addItem(ctx, {
          ...formData,
        })
      }
    }

    return {
      ...crudForm,
      updateItem,
      formData,
      submit,
    }
  },
}
</script>
